{#if status}
<div class="funnel">
  {#each status as data}
    <StatusColumn data="{data}" store="{store}" />
  {/each}
</div>
{/if}

<style>
  .funnel {
    width: 100%;
    height: calc(100% - 80px);
    overflow-x: scroll;
    overflow-y: hidden;
    white-space: nowrap;
  }
</style>

<script>
  import { Store } from 'svelte/store.js';
  import StatusColumn from 'components/applics/StatusColumn.html';

  export default {
    components: { StatusColumn },

    data() {
      return {
        store: new Store(),
        status: [{
          title: 'Новые',
          stats: [{
            value: 250,
            label: 'т.р.'
          }, {
            value: 4,
            label: 'заявки'
          }, {
            value: 2,
            label: 'т'
          }],
          applics: [{
            order_id: 2,
            id: 37359,
            type: 'tk'
          }, {
            order_id: 2,
            id: 37360,
            type: 'dl'
          }, {
            order_id: 3,
            id: 37361,
            type: 'tk'
          }, {
            order_id: 4,
            id: 37362,
            type: 'dl'
          }]
        }, {
          title: 'В обработке',
          stats: [{
            value: 132,
            label: 'т.р.'
          }, {
            value: 2,
            label: 'заявки'
          }, {
            value: 23,
            label: 'т'
          }],
          applics: [{ order_id: 4, id: 37363, type: 'tk' }, { id: 37364, type: 'dl' }]
        }, {
          title: 'Выполненные',
          stats: [{
            value: 15,
            label: 'т.р.'
          }, {
            value: 1,
            label: 'заявки'
          }, {
            value: 5,
            label: 'т'
          }],
          applics: [{ id: 37365, type: 'dl' }]
        }, {
          title: 'Отказ',
          stats: [{
            value: 657,
            label: 'т.р.'
          }, {
            value: 1,
            label: 'заявки'
          }, {
            value: 32,
            label: 'т'
          }],
          applics: [{ id: 37366 }]
        }, {
          title: 'Отмена',
          stats: [{
            value: 657,
            label: 'т.р.'
          }, {
            value: 1,
            label: 'заявки'
          }, {
            value: 32,
            label: 'т'
          }],
          applics: [{ id: 37366 }]
        }]
      }
    },

    onstate({ changed, current, previous }) {

    },

    oncreate() {
      const { store } = this.get();
      store.on('state', ({ changed, current, previous }) => {
        if(changed.searchOrderId){
          this.searchApplicsByOrderId(current.searchOrderId);
        }
      });
    },
    methods: {
      searchApplicsByOrderId(orderId) {
        if(typeof(orderId) != 'undefined'){
          var { status } = this.get();

          status = status.map(function(statusItem){
            statusItem.applics = statusItem.applics.map(function(applicItem){
              if(applicItem.order_id == orderId){
                applicItem.backlight = true;
              }
              return applicItem;
            });
            return statusItem;
          });
          //this.set({ status });
        }
      }
    }
  }
</script>