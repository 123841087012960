<div class="uk-flex " id="personalInfo-component">
  <div class="uk-width-1-3">
      <div ref:avatar on:click="inputEvent()" class="uk-margin-auto-vertical" style="background-color:#cecece;">
        {#if !isAvatar}
          <div class="uk-flex uk-flex-column ">
            <div class="uk-width-1-1 uk-text-center">
              <span uk-icon="camera"></span>
            </div>
            <div class="uk-width-1-1 uk-text-center">Добавить фото</div>
          </div>
        {/if}
      </div>
    <input type="file" id="inputAvatarUser" accept=".png, .jpg, .jpeg" style="display: none">
  </div>

  <div class="uk-width-2-3 uk-margin-remove uk-padding-remove" uk-grid>
    <div class="uk-width uk-margin-small-top uk-flex uk-flex-between">
      <div class="uk-width-1-2 uk-margin-small-right">
        <input ref:text class="uk-input" type="text" bind:value="user.first_name" placeholder="Имя" required>
      </div>
      <div class="uk-width-1-2 ">
        <input ref:text class="uk-input" type="text" bind:value="user.last_name" placeholder="Фамилия" >
      </div>
    </div>

    <div class="uk-width uk-margin-small-top">
      <input ref:text class="uk-input" type="text" bind:value="user.middle_name" placeholder="Отчество" >
    </div>

    <div class="uk-width uk-margin-small-top">
      <input ref:mail class="uk-input" type="email" bind:value="user.email" placeholder="Email" required>
    </div>
    <div class="uk-width uk-margin-small-top uk-margin-small-bottom">
      <input ref:phone class="uk-input g__input--simple" on:change="fixMask(event)" value="{phone}" type="text" placeholder="Телефон" >
    </div>

    <div class="uk-width uk-margin-small-top uk-margin-small-bottom">
      <label class="uk-form-label" >Тип транспорта</label>
      <div class="uk-form-controls ">
        <select bind:value="user.transport_type" class="uk-select g__input--simple">
          <option value="{null}">Нет</option>
          <option value="foot">Пеший</option>
          <option value="bicycle">Велосипед/Самокат</option>
          <option value="car">Автомобиль</option>
        </select>
      </div>
    </div>

    <div class="uk-width uk-margin-small-top uk-margin-small-bottom">
      <label class="uk-form-label" >Номер транспорта</label>
      <div class="uk-form-controls ">
        <input class="uk-input" type="text" bind:value="user.transport_number" placeholder="Номер транспорта">
      </div>
    </div>

    <div class="uk-width uk-margin-small-top uk-margin-small-bottom">
      <label class="uk-form-label" >Теги (через запятую)</label>
      <div class="uk-form-controls ">
        <input ref:tags class="uk-input" type="text" bind:value="user.tags" >
      </div>
    </div>

    <!-- Новые поля -->
    <div class="uk-width uk-margin-small-top uk-margin-small-bottom">
      <label class="uk-form-label" >KIS Art ID</label>
      <div class="uk-form-controls ">
        <input ref:kis_art_id class="uk-input" type="text" bind:value="user.kis_art_id" placeholder="KIS Art ID">
      </div>
    </div>

    <div class="uk-width uk-margin-small-top uk-margin-small-bottom">
      <label class="uk-form-label" >Номер сумки</label>
      <div class="uk-form-controls ">
        <input ref:bag_plates class="uk-input" type="text" bind:value="user.bag_plates" placeholder="Номер сумки">
      </div>
    </div>
  </div>
</div>

<script>
  import Validator from 'modules/validator.js';
  import mask from 'modules/mask.js';

  export default {
    oncreate(){
      const self = this;
      const {user} = this.get();
      const selfState = this.get();
      setTimeout(()=> {
        let phone = user.phone;
        this.set({phone});
        mask.phone(self.refs.phone);
      }, 100)
    },
    methods:{
      inputEvent(){
        let self = this;
        let fileInput = $('#inputAvatarUser');
        fileInput.trigger('click');
        fileInput.on('change', event => {
          this.set({isAvatar: true});
          if(document.getElementById('inputAvatarUser').files && document.getElementById('inputAvatarUser').files[0]){
            let fileReaderC = new FileReader();
            fileReaderC.onload = function () {
              $(self.refs.avatar).css('background-image', 'url(' + fileReaderC.result + ')');
            };
            fileReaderC.readAsDataURL(document.getElementById('inputAvatarUser').files[0]);
          }
        });
      },
      fixMask(event){
        let self = this;
        const {user} = self.get();
        let {phone} = self.get();
        phone = event.target.value;
        user.phone = mask.cleanVal(self.refs.phone);
        self.set({user, phone});
      }
    },
    data(){
      return {
        isAvatar: false,
        input: null,
      }
    },
  }
</script>

<style>.uk-input {
  height: 31px;
  line-height: 30px;
  border-radius: 4px; }

ref:avatar {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  background-position: 50%, 50%;
  background-size: cover;
  width: 155px;
  height: 155px; }
  ref:avatar:hover {
    opacity: 0.8; }

.uk-accordion-content {
  color: #4f4f4f; }

.uk-checkbox {
  border: 1px solid #000000; }
  .uk-checkbox:checked {
    background-color: #E30613; }

.uk-select,
input {
  border-radius: 4px; }
</style>