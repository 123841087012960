<div ref:main class="uk-section uk-section-default uk-height-1-1 uk-padding-small" data-intro='Добавьте минимум два адреса' data-step='2'>
  {#if headerInfoVisible}
  <div class="header-info uk-position uk-position-top-left uk-background-default uk-width" style="z-index: 999;">
      <span class="uk-position-right uk-flex uk-flex-center uk-icon uk-icon-image"
            on:click="set({ headerInfoVisible: false })" style="margin-right: 20px; cursor: pointer; background-image: url(/svg/icons/close.svg);" >
      </span>
      {#if step === 1}
        <div class="uk-width uk-margin-small">
          <div class="uk-text-center rout-header">Особенности маршрута</div>
        </div>

        <div class="uk-padding-small uk-padding-remove-vertical" uk-grid>
          <div class="uk-width-3-5 text__14">
            Максимальное время поездки:
          </div>

          <div class="uk-width-2-5">
            <input class="uk-input uk-text-right" bind:value=travel type="text"><span class="uk-margin-small-left">часа</span>
          </div>
        </div>

        <div class="uk-padding-small uk-padding-remove-vertical uk-margin-small-top" uk-grid>
          <div class="uk-width-3-5 text__14">
            Ограничение по пробегу:
          </div>

          <div class="uk-width-2-5">
            <input class="uk-input uk-text-right" bind:value=distance type="text"><span class="uk-margin-small-left">км</span>
          </div>
        </div>
      {/if}

      {#if step === 4}
        <div class="uk-width">
          <p class="uk-text-center text__16">Дополнительное время на точку</p>
        </div>

        <div class="uk-padding-small uk-padding-remove-vertical" uk-grid>
          <div class="uk-width-3-5 text__14">
            Среднее время погрузки:
          </div>

          <div class="uk-width-2-5">
            <input class="uk-input uk-text-right" bind:value=tkTime type="text"><span class="uk-margin-small-left text__14">минут</span>
          </div>
        </div>

        <div class="uk-padding-small uk-padding-remove-vertical uk-margin-small-top" uk-grid>
          <div class="uk-width-3-5 text__14">
            Среднее время разгрузки:
          </div>

          <div class="uk-width-2-5">
            <input class="uk-input text__14 uk-text-right" bind:value=dlTime type="text"><span class="uk-margin-small-left text__14">минут</span>
          </div>
        </div>
      {/if}
  </div>
  {/if}

  <div>
    {#if headerInfoVisible}<div class="overlay"></div>{/if}
    <div ref:header>
      <hr />
      <div class="uk-flex uk-width uk-position-relative" style="min-height: 45px;">
        <div class="uk-margin-auto-vertical uk-width" style="margin-left: 25px;">
          <div class="uk-position-relative">
            {#if step === 1}
              <p class="uk-text-left uk-margin-small-bottom">Точки маршрута</p>
            {/if}
  
            {#if step === 2}
              <p class="uk-text-left uk-margin-small-bottom">Распределите точки по типу (Забор, Доставка)</p>
            {/if}
  
            {#if step === 3}
              <p class="uk-text-left uk-margin-small-bottom">Соотнесите доставку с забором</p>
            {/if}
            
            {#if step === 4}
              <input class="uk-input" style="width: 50%; height: 30px;" type="text" placeholder="Название маршрута" bind:value=routeName>
            {/if}
  
            {#if step === 4}
              <span class="uk-position-right uk-flex uk-flex-center uk-height-1-1" uk-icon="cog" 
                    on:click="set({ headerInfoVisible: true })" style="margin-right: 20px; cursor: pointer;" >
              </span>
            {/if}
          </div>
          
          {#if step === 1}
            <div class="uk-margin-small-bottom" data-intro='Можно добавлять адреса по одному (вручную) или списком' data-step='1'>
              <button on:click="set({ newPoint: true })" class="uk-button uk-button-text button-icon uk-margin-small-right">
                <span uk-icon="pencil" class="uk-position-left"></span>
                Добавить вручную
              </button>
              <button uk-toggle="target: #downloadPointsListModal" class="uk-button uk-button-text button-icon">
                <span uk-icon="list" class="uk-position-left"></span>
                Загрузить списком
              </button>
              {#if step === 1}
                <div class="uk-margin-small-top uk-margin-small-right">
                {#if newPoint}
                  <WayPoint
                      on:new="addPoint(event)"
                      on:incorrect="clearNewPoint()"
                      on:addNewPoint="addNewApiPoint(event)"
                      {index}
                      />
                {/if}
                </div>
              {/if}
            </div>
          {/if}
          
          {#if step === 2}
            <div class="uk-margin-small-bottom">
              <span class="uk-margin-small-right">Отметить как:</span>
              <button on:click="bindAddress('tk')" class="uk-button uk-button-text button-icon green uk-margin-small-right">
                <span uk-icon="pull" class="uk-position-left"></span>
                Забор
              </button>
              <button on:click="bindAddress('dl')" class="uk-button uk-button-text button-icon red">
                <span uk-icon="push" class="uk-position-left"></span>
                Доставка
              </button>
            </div>
          {/if}
  
          {#if step === 4}
          <div class="uk-padding-small uk-padding-remove-vertical uk-grid-small uk-margin-small-top uk-margin-small-bottom" uk-grid>
            <div class="uk-width-1-2 text__14 ">
              Общая длина: <span class="uk-text-bold">{distanceText}</span>
            </div>
        
            <div class="uk-width-1-2 text__14 ">
              Время в пути: <span class="uk-text-bold">{durationText}</span>
            </div>
        
            <div class="uk-width-1-2 uk-text-left uk-margin-remove-top text__14 margin-left-half">
              Всего: <span class="uk-text-bold">{durationTextWithPoints}</span>
            </div>
  
            <div class="uk-width-2-3 uk-text-left">
              <p class="uk-text-left">Выставите очередность точек</p>
            </div>
  
            <div class="uk-width-1-3 uk-text-right">
              <button on:click="clearOrder()" class="uk-button uk-button-text button-icon red">
                <span uk-icon="refresh" class="uk-position-left"></span>
                Сбросить
              </button>
            </div>
          </div>
          {/if}
        </div>
  
        
      </div>
    </div>

    <div ref:body class:lg-top="step === 1 || step === 2" class:xlg-top="newPoint" on:click="clearNewPoint()">
      


  {#if step === 1 || step === 2}
    {#if editStore.get().points && editStore.get().points.length > 0}
    <div id="points-list">
      <PointList {editStore} {isTypeSelect} isChangedAddress={true} actions={pointListActions} />
    </div>
    {:else}
    <div class="uk-padding uk-text-justify">
        <div class="uk-text-center">
            <svg class="arrows">
              <path class="a1" d="M0 0 L30 32 L60 0"></path>
              <path class="a2" d="M0 20 L30 52 L60 20"></path>
              <path class="a3" d="M0 40 L30 72 L60 40"></path>
            </svg>
        </div>
        <span class="uk-text-muted">Нет точек. Чтобы добавить новые точки, нажмите на "Добавить вручную" или "Загрузить списком".</span>
    </div>
    {/if}
  {/if}





  {#if step === 3 || step === 4}
    {#if step === 3}
    <div class="uk-flex uk-height-1-1">
      <div class="uk-height-1-1 uk-width-1-2" style="max-height: 100%; overflow: auto; padding: 0 5px;">
        <PointList {editStore} routeType=tk hideOrdersInfo={true} actions={pointListActions} on:pointClick="setOrder(event)" {activeTk} />
      </div>
      <div class="uk-height-1-1 uk-width-1-2" style="max-height: 100%; overflow: auto; padding: 0 5px;">
        <PointList {editStore} {isDraggable} hideOrdersInfo={true} routeType=dl actions={pointListActions} on:pointClick="setOrder(event)" />
      </div>
    </div>
    {:elseif step === 4}
    <div style="margin-top: 95px;">
      <PointListWithRelation {editStore} on:pointClick="setOrder(event)" />
    </div>
    <div class="uk-position uk-position-bottom-right">
      <button on:click="saveRoutes()" class="uk-button uk-button-small" style="color: green; border-color: green;">Сохранить маршуты</button>
    </div>
    {/if}

    
  {/if}
    </div>
    
    <div ref:footer>
      <button on:click="dec()" class="uk-button g__btn--no uk-button-small uk-margin-small-right">Предыдущий шаг</button>
      {#if step !== 4}
        <button on:click="inc()" class="uk-button g__btn--yes uk-button-small" id="route-editor-next-step">Следущий шаг</button>
      {:else}
        <button on:click="autoRoute()" class="uk-button uk-button-small" style="color: green; border-color: green;" uk-tooltip="title: Автоматическое составление маршрута находится в доработке; pos: top-left">Автомаршрут</button>
        <button on:click="setRoute()" class="uk-button g__btn--yes uk-button-small">Создать маршрут</button>
      {/if}
    </div>
  </div>

<p class="btn-group uk-text-right">
  {#if step === 3}
<!--        <div class="uk-width-expand">-->
<!--          <select on:change="filter(this.value)" class="uk-select">-->
<!--            <option value="nd">Показать все</option>-->
<!--            <option value="dl">Только доставка</option>-->
<!--            <option value="tk">Только заборы</option>-->
<!--          </select>-->
<!--        </div>-->
  {/if}
</p>

</div>

<div id="downloadPointsListModal" uk-modal>
    <div class="uk-modal-dialog uk-modal-body">
        <h2 class="uk-modal-title">Загрузить точки списком</h2>
        <textarea class="uk-textarea" rows="10" ref="pointListTextarea" bind:value=pointListToDownload></textarea>
        <p class="uk-text-right">
            <button class="uk-button g__btn--no uk-modal-close" type="button" on:click="set({pointListToDownload: ''})">Отмена</button>
            <button class="uk-button g__btn--yes uk-modal-close" type="button" on:click="pointListDownload()">Загрузить</button>
        </p>
    </div>
</div>

<Helper
   {textData}
   {step}
 />


<style>@charset "UTF-8";
.n__btn--primary {
  background-color: #c70017;
  color: white;
  font-weight: 500;
  border-radius: 0;
  line-height: 36px;
  text-transform: unset;
  padding: 0 15px;
  font-size: 16px;
  height: 100%; }
  .n__btn--primary img {
    margin-top: -3px;
    margin-right: 7px; }

ref:header {
  background-color: white;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  min-height: 45px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.08);
  z-index: 9; }

ref:header p {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: #666666;
  margin: 0; }

ref:footer {
  text-align: right;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 15px;
  height: 30px;
  background-color: white;
  box-shadow: 0 -5px 15px rgba(0, 0, 0, 0.08); }

ref:body {
  position: absolute;
  top: 60px;
  bottom: 60px;
  padding: 10px;
  left: 0;
  right: 0;
  overflow-y: auto;
  background-color: #f5f5f5; }

ref:body.lg-top {
  top: 80px; }

ref:body.xlg-top {
  top: 120px; }

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.28);
  z-index: 99; }

.margin-left-half {
  margin-left: 50%; }

.text__16 {
  font-size: 16px; }

h5 {
  color: #2e2e2e; }

.reset u {
  font-size: 12px; }

.g__btn--yes {
  text-transform: unset;
  border-radius: 0;
  background: #FFFFFF;
  color: #e30613;
  border: 1px solid #e30613;
  border-radius: 4px; }
  .g__btn--yes:hover {
    background: #e30613;
    color: #ffffff; }

.g__btn--no {
  text-transform: unset;
  border-radius: 0;
  border-radius: 4px; }
  .g__btn--no:hover {
    background: #c4c4c4;
    color: #ffffff; }

.g__btn--green {
  width: 45%;
  text-transform: unset;
  border-radius: 0;
  border-radius: 4px;
  background-color: #fff;
  color: #28B446;
  border: 1px solid #28B446; }
  .g__btn--green:hover {
    background: #28B446;
    color: #ffffff; }
  .g__btn--green:hover {
    color: #ffffff;
    background: #28B446; }

.g__btn--red {
  width: 50%;
  text-transform: unset;
  border-radius: 0;
  border-radius: 4px;
  background-color: #fff;
  color: #E30613;
  border: 1px solid #E30613; }
  .g__btn--red:hover {
    background: #e30613;
    color: #ffffff; }
  .g__btn--red:hover {
    color: #ffffff;
    background: #E30613; }

.g__btn--link {
  background: #FFFFFF;
  color: #c4c4c4;
  text-transform: unset;
  border: none; }
  .g__btn--link:hover {
    color: #c2c2c2; }

.g__btn--simple {
  text-transform: unset;
  border-radius: 0;
  color: #000000;
  text-transform: unset;
  border-radius: 4px;
  border: 1px solid #c4c4c4; }
  .g__btn--simple:hover {
    background: #faa05a;
    color: #ffffff; }
  .g__btn--simple:hover {
    color: #ffffff;
    background: #c4c4c4; }

.uk-input {
  width: 51px;
  height: 21px;
  border-radius: 4px;
  border: 1px solid #c4c4c4; }

ref:points {
  max-height: 60%;
  overflow-y: auto; }

ref:main {
  position: absolute;
  width: 500px;
  top: 0;
  left: 60px;
  overflow-y: auto;
  padding-top: 0; }

.rout-header {
  color: #000000;
  font-size: 16px;
  text-transform: unset; }

.uk-text-bold {
  color: #000000; }

#new-route-btn {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 999; }

.header-info {
  padding: 0 0 25px 0; }

.arrows {
  width: 60px;
  height: 72px;
  margin-bottom: 25px;
  transform: rotate(180deg); }

.arrows path {
  stroke: #c4c4c4;
  fill: transparent;
  stroke-width: 1px;
  animation: arrow 2s infinite;
  -webkit-animation: arrow 2s infinite; }

@keyframes arrow {
  0% {
    opacity: 0; }
  40% {
    opacity: 1; }
  80% {
    opacity: 0; }
  100% {
    opacity: 0; } }

@-webkit-keyframes arrow /*Safari and Chrome*/ {
  0% {
    opacity: 0; }
  40% {
    opacity: 1; }
  80% {
    opacity: 0; }
  100% {
    opacity: 0; } }

.arrows path.a1 {
  animation-delay: -1s;
  -webkit-animation-delay: -1s;
  /* Safari 和 Chrome */ }

.arrows path.a2 {
  animation-delay: -0.5s;
  -webkit-animation-delay: -0.5s;
  /* Safari 和 Chrome */ }

.arrows path.a3 {
  animation-delay: 0s;
  -webkit-animation-delay: 0s;
  /* Safari 和 Chrome */ }
</style>

<script>
  import ky from 'ky';

  import Phalcon from 'modules/phalcon.js';

  import RoutCard from 'components/routes/RoutesCard.html';
  import PointList from 'components/points/PointsList.html';
  import PointListWithRelation from 'components/points/PointsListWithRelation.html';
  import WayPoint from 'components/points/PointsCard.html';

  import Helper from 'UI/Helper.html';

  import { Store } from 'svelte/store.js';

  var firstClear = true;

  export default {
    onupdate({ changed, current }) {
      if (changed.points && current.points && current.points.length > 0) {
        current.editStore.set({ points: current.points });
      }
      if (changed.step) {
        this.refreshStepVisible();
      }

      if (changed.routeSaving) {
        if (current.routeSaving == current.routes.length && current.step == 4 && current.routes.length != 0) {
          Swal.close();
          let date = Phalcon.getUrlVars()['date'];
          if (!date) date = current.date;

          let url = '/map#routes';
          if (date) url += '?date_start=' + date + '&date_end=' + date;

          this.options.root.navigate(url);
        }
      }

      if (changed.pointsToReturn) {
        ////console.log(current.pointsToReturn);
        if (current.pointsToReturn.length > 0) {
          let { points } = current.editStore.get();
          let pointsToReturn = [];

          let pointsIds = points.map(p => {
            return p.id;
          })

          current.pointsToReturn.map(p => {
            if (!pointsIds.includes(p.id)) {
              pointsToReturn.push(p);
            }
          })

          points = points.concat(pointsToReturn);
          current.editStore.set({ points });
          window.map.helper.clearAllInstanceListeners(points);

          this.clearOrder();

          window.map.helper.refreshLabels(points);

          this.setRelatedPoints();
          window.map.helper.setListenerToPoints(points, this.markerOnClickSetOrder, this);
          window.map.helper.setListenerToPoints(points, this.markerOnClickRemoveFromOrder, this, 'rightclick');

          pointsToReturn.map(p => {
            if (p.type == "dl") {
              let tks = current.pointTksMap.get(p.id);
              if (tks) tks.map(tk => {
                window.map.helper.setPolyline(tk.id + '-' + p.id);
              });
            }
          });
        }
      }
    },
    ondestroy() {
      window.nav.clear();
      window.map.helper.destroy();
      firstClear = true;
    },
    onstate({ current, changed }) {
      if(changed.loading){
        console.log(current.loading);
        if(current.loading){
          Swal.fire({
            title: 'Идет загрузка!',
            html: 'Подождите...',
            onBeforeOpen: () => { 
              Swal.showLoading();
            }
          });
        } else {
          Swal.close();
        }
      }
    },
    oncreate() {
      const self = this;
      var { points, editStore } = this.get();
      const { googleMap, markers, polylines } = window.map.store.get();

      //console.log({ points });
      if (editStore) {
        editStore.set({ points });
        editStore.on('state', ({ changed, current }) => {
          if (changed.points) {
            self.set({ index: current.points.length + 1 });
            window.map.helper.refreshLabels(current.points);

            if(current.points.length == 2) {
              let { step } = self.get();
              let introStorage = localStorage.getItem('route_edit_intro_step_1_points');
              if(step == 1 && !introStorage) self.showIntro(2);
            }
          }
        })
        setTimeout(() => {
          let step = Phalcon.getUrlVars()['step'] ? parseInt(Phalcon.getUrlVars()['step']) : 1;
          self.set({ step });
        }, 300);
      }

      this.set({ index: points.length + 1 });
    },
    methods: {
      clearNewPoint() {
        setTimeout(() => this.set({ newPoint: false }), 100)
      },
      showIntro(part = 1) {
        const self = this;

        let intro = introJs();
        intro.setOption("skipLabel", "Пропустить обучение");
        intro.setOption("nextLabel", "Дальше");
        intro.setOption("prevLabel", "Назад");
        intro.setOption("doneLabel", "Завершить");
        //console.log($('[data-step=1]'));

        if(part == 1) {
          intro.setOptions({
            steps: [{
              element: $(this.refs.main).find('[data-step=1]')[0],
              intro: $(this.refs.main).find('[data-step=1]').attr('data-intro')
            }, {
              element: this.refs.main,
              intro: $(this.refs.main).attr('data-intro')
            }]
          });

          localStorage.setItem('route_edit_intro_step_1', 'true');
        }

        if(part == 2) {
          intro.setOptions({
            steps: [{
              element: $('#google-map')[0],
              intro: 'Вы можете уточнить адрес простым перетаскиванием маркера на карте, вплоть до подъезда'
            }, {
              element: $('#route-editor-next-step')[0],
              intro: 'Если все готово, нажмите на кнопку "следующий шаг"'
            }]
          });

          localStorage.setItem('route_edit_intro_step_1_points', 'true');
        }

        if(part == 3) {
          intro.setOptions({
            steps: [{
              element: this.refs.main,
              intro: 'На Шаге 2, вы должны распределить точки по их типу (забор/доставка)'
            }, {
              element: $('#points-list')[0],
              intro: 'Выберите одну точку из списка. Сделать это можно при помоще проставления галочек слева от точек.'
            }, {
              element: this.refs.header,
              intro: 'После того как отметили нужные точки, нажмите на одну из кнопок "Забор" или "Доставка"'
            }, {
              element: $('#google-map')[0],
              intro: 'Так же это можно сделать кликами по маркеру на карте'
            }, {
              element: $('#route-editor-next-step')[0],
              intro: 'Если все готово, нажмите на кнопку "следующий шаг"'
            }]
          });

          localStorage.setItem('route_edit_intro_step_2', 'true');
        }

        if(part == 4) {
          intro.setOptions({
            steps: [{
              element: this.refs.main,
              intro: 'На Шаге 3, вы должны установить связь между точками'
            }, {
              element: $('#dl-list')[0],
              intro: 'Здесь находятся отмеченные вами доставки'
            }, {
              element: $('#tk-list')[0],
              intro: 'Здесь находятся отмеченные вами заборы'
            }, {
              element: this.refs.body,
              intro: 'Простым перетаскиванием доставок, вы можете установить связь между ними (Откуда -> Куда)'
            }, {
              element: $('#route-editor-next-step')[0],
              intro: 'Если все готово, нажмите на кнопку "следующий шаг"'
            }]
          });

          localStorage.setItem('route_edit_intro_step_3', 'true');
        }

        if(part == 5) {
          intro.setOptions({
            steps: [{
              element: this.refs.main,
              intro: 'На Шаге 4, вы должны установить очередность точек в маршруте. Учтите, что выполнение доставки товара невозможна до его забора (система поставит забор в очередь автоматически при ошибке)'
            }, {
              element: this.refs.body,
              intro: 'Это можно сделать кликая на точку в списке'
            }, {
              element: $('#google-map')[0],
              intro: 'Так же это можно сделать на карте. Левый клик - добавить точку в очередь; Правая кнопка - убрать точку из очереди'
            }, {
              element: $('#route-editor-next-step')[0],
              intro: 'Если все готово, нажмите на кнопку "Создать маршрут"'
            }]
          });

          localStorage.setItem('route_edit_intro_step_4', 'true');
        }

        // intro.oncomplete(function(){
        //   self.newRouteBtnOnClick();
        // });

        intro.start();

        this.set({intro});
      },
      filter(routeType) {
        if (routeType) {

          ////console.log(`routType is: ${routeType}`);
          // const {points} = this.get();
          // ////console.log("Before insert:", defaultData);
          // if (!routeType.type) {
          // this.set({data: defaultData});
          // return;
          // }
          // let data = defaultData.filter(item => {
          //   if(!item.groups) return false;
          //   if(item.groups.key === currentCategory.group.key) return true;
          //   return false;
          // });
          // // ////console.log("Sorted data:", data);
          // this.set({data});
        }
      },
      bindAddress(type) {
        const { editStore } = this.get();
        let { checked, points } = editStore.get();

        points = points.map(item => {
          if (item.checked) {
            item.type = type;
            item.checked = false;
            window.map.helper.refreshIcon(item);
          }
          return item;
        });

        editStore.set({ points });
        this.set({ checked: [] });
      },
      async pointListDownload() {
        const self = this;
        
        Swal.fire({
          title: 'Идет загрузка точек!',
          html: 'Подождите...',
          onBeforeOpen: () => { 
            Swal.showLoading();
          }
        });

        var { pointListToDownload, editStore } = this.get();
        let { points } = editStore.get();

        let pointsToCreate = [];

        pointListToDownload = pointListToDownload.split('\n');

        //console.log({pointListToDownload});

        for(var i = 0; i < pointListToDownload.length; i++){
          let answer = await this.apiPoint(pointListToDownload[i]);

          //console.log({answer, pointsToCreate});
          if(answer && answer.results && answer.results[0]){
            pointsToCreate.push({
              address: answer.results[0].formatted_address,
              lat: answer.results[0].geometry.location.lat,
              lon: answer.results[0].geometry.location.lng,
              type: 'nd',
              index: points.length + pointsToCreate.length + 1
            });
          } else {
            UIkit.notification({
              message: 'Ошибка гугл карт!',
              status: 'danger',
              pos: 'bottom-right'
            });
          }
        }
        
        let answer = await Phalcon.points.createFromArray(pointsToCreate);

        if (answer.status === 200) {
          points = points.concat(answer);

          points = points.map((p, i) => {
            p.id = parseInt(p.id);
            p.index = i + 1;
            return p;
          })

          //console.log({points});

          editStore.set({ points });
          this.set({ newPoint: false });

          window.map.helper.clearAllInstanceListeners(points);
          window.map.helper.setDraggable(points);
          window.map.helper.setListenerToPoints(points, self.markerOnDragChangeAddress, self, 'dragend');
          window.map.helper.fitBounds(points);
        }

        Swal.close();
        this.set({pointListToDownload: ''});
      },
      apiPoint(address) {
        const apiKey = "AIzaSyAvmvgCSleLj0_RBUVyOcqMglX7qs19yyk";
        return fetch(`https://maps.google.com/maps/api/geocode/json?address=${address}&key=${apiKey}`).then(responce => {
          if (responce.ok) {
            return responce.json();
          } else {
            //throw new Error("answer isn't ok!")
          }
        });
      },
      addNewApiPoint(address) {
        this.apiPoint(address).then(answer => {
          this.sendPoint(answer.results);
        });
      },

      async sendPoint(data) {
        if (!data.length) {
          UIkit.notification({
            message: 'Один из адресов не найден! Попробуйте уточнить адрес.',
            status: 'danger',
            pos: 'bottom-right'
          });
          return;
        }

        let pointCreate = {};
        pointCreate.address = data[0].formatted_address;
        pointCreate.lat = data[0].geometry.location.lat;
        pointCreate.lon = data[0].geometry.location.lng;

        await this.addPoint(pointCreate);
      },

      async addPoint(event) {
        const self = this;

        let { editStore } = this.get();
        let { points } = editStore.get();

        if (!points) {
          points = [];
        }

        event.id = parseInt(event.id);
        event.index = points.length + 1;

        let answer = await Phalcon.points.create(event);

        if (answer.status === 200) {
          answer.id = parseInt(answer.id);
          points.push(answer);

          points = points.map((p, i) => {
            p.index = i + 1;
            return p;
          })

          editStore.set({ points });
          this.set({ newPoint: false });

          window.map.helper.clearAllInstanceListeners(points);
          window.map.helper.setDraggable(points);
          window.map.helper.setListenerToPoints(points, self.markerOnDragChangeAddress, self, 'dragend');
          window.map.helper.fitBounds(points);
        }
      },

      markerOnClickSetOrder(point, e, self) {
        self.setOrder(point);
      },
      markerOnClickRemoveFromOrder(point, e, self) {
        self.removeFromOrder(point);
      },
      markerOnClickSetTk(point, e, self, marker) {
        point.type = 'tk';
        self.refreshPoints(point);
        window.map.helper.refreshIcon(point);
      },
      markerOnClickSetDl(point, e, self, marker) {
        point.type = 'dl';
        self.refreshPoints(point);
        window.map.helper.refreshIcon(point);
      },
      markerOnClickSetRelations(point, e, self, marker) {
        ////console.log('markerOnClickSetRelations');
        let { activeTk } = self.get();
        if (point.type == 'tk') {
          window.map.helper.removeActive();
          if(activeTk != point) {
            window.map.helper.setActive(point);
            self.set({ activeTk: point });
          } else {
            self.set({ activeTk: null });
          }
        } else if (point.type == 'dl') {
          if (activeTk) {
            self.movePointToTk(point);
          }
        }
      },
      movePointToTk(point) {
        const self = this;
        const { activeTk, editStore } = this.get();
        let { points } = editStore.get();
        let dlEl = $('#dl-list').find(`.dl-list-item[data-point-id=${point.id}]`)[0];
        let tkEl = $(`.dl-container[data-point-id=${activeTk.id}]`)[0];
        let el = $(`.dl-list-item[data-point-id=${point.id}]`)[0];
        let tk_id = parseInt($(tkEl).attr('data-point-id'));

        if (activeTk.dl) {
          if(!activeTk.dl.includes(point)) {
            activeTk.dl.push(point);

            if(dlEl) {
              $(tkEl).append($(dlEl));
            } else {
              $(tkEl).append(el.outerHTML);
            }
          } else {
            el = $(tkEl).find(`.dl-list-item[data-point-id=${point.id}]`)[0];
            activeTk.dl = activeTk.dl.filter(dl => dl.id != point.id);
            window.map.helper.hidePolyline(activeTk.id + '-' + point.id);
            
            if(!dlEl) {
              if($(`.dl-list-item[data-point-id=${point.id}]`).length == 1){
                $('#dl-list').append($(el));
              } else {
                $(el).remove();
              }
            } else {
              $(el).remove();
            }
          }
        } else {
          activeTk.dl = [point];
          
          if(dlEl) {
            $(tkEl).append($(dlEl));
          } else {
            $(tkEl).append(el.outerHTML);
          }
        }

        self.refreshEdges(tkEl);
      },
      refreshEdges(el, removeId) {
        //console.log({el});
        const id = $(el).attr('data-point-id'),
          { editStore } = this.get(),
          { points } = editStore.get();

        let point = points.filter(point => point.id == id)[0],
          elms = $(el).find("li");
        if (point) {
          if (point.dl) {
            point.dl.map(p => {
              window.map.helper.hidePolyline(point.id + '-' + p.id);
            })

          }

          var pointsIds = [];
          elms.each((index, item) => {
            pointsIds.push(parseInt($(item).attr('data-point-id')));
          });

          points.map(tkPoint => {
            if (tkPoint.type == 'tk' && tkPoint.id == point.id) {
              points.map(dlPoint => {
                if (dlPoint.type == 'dl') {
                  if (pointsIds.indexOf(dlPoint.id) != -1) {
                    window.map.helper.setPolyline(tkPoint.id + '-' + dlPoint.id, [{
                      lat: parseFloat(tkPoint.lat),
                      lng: parseFloat(tkPoint.lon)
                    }, {
                      lat: parseFloat(dlPoint.lat),
                      lng: parseFloat(dlPoint.lon)
                    }]);
                  }
                }
              });
            }
          });
        }
      },
      setRelationToTk(tk, dl) {
        if (!tk.dl) {
          tk.dl = [dl];
        } else {
          if (tk.dl.indexOf(dl) == -1) tk.dl.push(dl);
        }
        dl = null;
        this.refreshPoints(tk);
        this.refreshPoints(dl);
      },
      markerOnDragChangeAddress(point, e, self, marker) {
        var pos = marker.getPosition();
        window.map.helper.geocode(pos).then(address => {
          ////console.log(address, pos.lat(), pos.lng());

          if (address) {
            if (!point.type) point.type = 'nd';
            point.address = address;
            point.lat = pos.lat();
            point.lon = pos.lng();
            self.refreshPoints(point);

            Phalcon.points.update(point.id, point).then(answer => {
              if (answer.status == 200) {
                UIkit.notification({
                  message: 'Адрес обновлен',
                  status: 'success',
                  pos: 'bottom-right'
                });
              }
            })
          }
        });
      },
      inc() {
        let { step, editStore, pointTksMap, intro } = this.get();
        let { points } = editStore.get();

        if(intro) intro.exit();

        let isCorrect = true;
        if (step == 1) {
          if (points.length < 2) {
            UIkit.notification({
              message: 'Для составления маршрута должно быть минимум 2 точки!',
              status: 'danger',
              pos: 'bottom-right'
            });
            isCorrect = false;
          }
        }

        if (step == 2) {
          points.map(point => {
            if (!point.type || point.type == 'nd') {
              isCorrect = false;
            }
          })
          if (!isCorrect) {
            UIkit.notification({
              message: 'Распределите все точки по типу!',
              status: 'danger',
              pos: 'bottom-right'
            });
          }
        }

        if (step == 3) {
          this.setRelatedPoints();

          points.map(point => {
            if (point.type == 'dl' && !point.application) {
              if (!pointTksMap.get(point.id)) isCorrect = false
            }
          });

          if (!isCorrect) {
            UIkit.notification({
              message: 'Не все точки связаны!',
              status: 'danger',
              pos: 'bottom-right',
            });
          }
        }

        if (isCorrect) {

          if(step == 3){
            points.map(point => {
              if (point.type == 'tk'){
                if(point.dl) point.dl_ids = point.dl.map(point => point.id).join(',');
              }
            });

            window.map.helper.removeActive();
          }

          step++;
          this.set({ step });
        }
      },
      refreshStepVisible() {
        const self = this;
        let { step, editStore, pointTksMap } = this.get();
        let { points } = editStore.get();

        if (step === 1) {
          window.nav.set({
            title: 'Шаг 1 из 4. Добавление точек'
          });

          setTimeout(() => {
            let { points } = editStore.get();
            window.map.helper.clearAllInstanceListeners(points);
            window.map.helper.setListenerToPoints(points, self.markerOnDragChangeAddress, self, 'dragend');
            window.map.helper.setDraggable(points);
          },1000);

          this.set({ isTypeSelect: false, index: points.length + 1, pointListActions: ['copy', 'remove'] });

          let introStorage = localStorage.getItem('route_edit_intro_step_1');
          if(!introStorage) setTimeout(() => {
            self.showIntro();
          },1000);
        }

        if (step === 2) {
          ////console.log('Шаг 2 из 4');
          window.nav.set({
            title: 'Шаг 2 из 4. Распределение точек по типу'
          });
          this.set({ isTypeSelect: true, pointListActions: ['copy', 'remove'] });
          window.map.helper.clearAllInstanceListeners(points);
          window.map.helper.removeDraggable(points);
          window.map.helper.setListenerToPoints(points, this.markerOnClickSetDl, this, 'click');
          window.map.helper.setListenerToPoints(points, this.markerOnClickSetTk, this, 'rightclick');
          window.map.helper.clearPolylines();
          //this.configRoute();

          let introStorage = localStorage.getItem('route_edit_intro_step_2');
          if(!introStorage) setTimeout(() => {
            self.showIntro(3);
          },1000);
        }

        if (step === 3) {
          ////console.log('Шаг 3 из 4');
          pointTksMap.clear();
          window.map.helper.removeDraggable(points);
          window.map.helper.clearAllInstanceListeners(points);
          window.map.helper.setListenerToPoints(points, this.markerOnClickSetRelations, this, 'click');

          window.nav.set({
            title: 'Шаг 3 из 4. Настройка связей адресов'
          });

          this.set({ pointListActions: ['copy'] });

          let tks = points.map(point => {
            if (point.type == 'tk') return point.id
          }).filter(id => id);

          let tkIds = tks.join(',');

          let dls = points.map(point => {
            if (point.type == 'dl') return point.id
          }).filter(id => id);

          let dlIds = dls.join(',');

          Phalcon.points.type('tk', {
            ids: tkIds
          });

          Phalcon.points.type('dl', {
            ids: dlIds
          });


          ////console.log("AGSGASGAS", tks);
          points = points.map((p, i) => {
            delete p.edges;
            delete p.dl;

            if (p.type == 'tk') {
              if (tks.length == 1) {
                p.dl = points.filter(point => point.type == 'dl');
              } else {
                if(p.dl_ids){
                  let dl_ids = Array.isArray(p.dl_ids) ? p.dl_ids : p.dl_ids.split(',');

                  this.set({ activeTk: p });
                  points.map(point => {
                    if(dl_ids.includes(point.id.toString())){
                      this.movePointToTk(point);
                    }
                  })
                }
              }
            }
            p.index = i + 1;

            delete p.dl_ids;

            return p;
          });
          
          //console.log('STEP 2', {points});
          editStore.set({ points });
          window.map.helper.refreshLabels(points);
          window.map.helper.hideAllRoutes();

          // points.map(point => {
          //   if(point.type == 'tk') {
          //     if(point.dl_ids){
          //       let dl_ids = point.dl_ids.split(',');

          //       this.set({ activeTk: point });
          //       points.map(p => {
          //         if(dl_ids.includes(p.id.toString())){
          //           this.movePointToTk(p);
          //         }
          //       })
          //     }
          //   }
          // })

          // if (tks.length == 1) {
          //   window.map.helper.removeDraggable(points);
          //   ////console.log('Шаг 4 из 4');
          //   window.nav.set({
          //     title: 'Шаг 4 из 4. Подтверждение и выставление очередности'
          //   });

          //   this.setRelatedPoints();
          //   this.clearOrder();

          //   window.map.helper.setListenerToPoints(points, this.markerOnClickSetOrder, this);
          //   window.map.helper.setListenerToPoints(points, this.markerOnClickRemoveFromOrder, this, 'rightclick');

          //   this.set({ pointListActions: [], isDraggable: false });
          //   this.inc();
          // }

          let introStorage = localStorage.getItem('route_edit_intro_step_3');
          if(!introStorage) setTimeout(() => {
            self.showIntro(4);
          },1000);

          setTimeout(() => {
            self.set({ activeTk: null });
          })
        }

        if (step == 4) {
        
          ////console.log('Шаг 4 из 4');
          window.nav.set({
            title: 'Шаг 4 из 4. Подтверждение и выставление очередности'
          });

          this.setRelatedPoints();
          this.clearOrder();

          let waitLoadingInterval = setInterval(() => {
            const { loading } = self.get();

            if(!loading){
              setTimeout(() => {
                let { points } = editStore.get();
                window.map.helper.removeDraggable(points);
                window.map.helper.clearAllInstanceListeners(points);
                window.map.helper.setListenerToPoints(points, self.markerOnClickSetOrder, self);
                window.map.helper.setListenerToPoints(points, self.markerOnClickRemoveFromOrder, self, 'rightclick');

                clearInterval(waitLoadingInterval);
              }, 500);
            }
          },300);

          this.set({ pointListActions: [], isDraggable: false });

          let introStorage = localStorage.getItem('route_edit_intro_step_4');
          if(!introStorage) setTimeout(() => {
            self.showIntro(5);
          },1000);

          //console.log({points});
        }

      },
      saveRoute(route) {
        const self = this;
        let applications_ids = Phalcon.getUrlVars()['applications_ids'];
        let date = Phalcon.getUrlVars()['date'];

        if (applications_ids) {
          let points_arr = [];
          let date = moment().format('YYYY-MM-DD');

          route.points.map(point => {
            if (point.type == 'tk' && point.tks) {
              point.tks.map(tk => {
                let temp = {
                  id: tk.id,
                  index: point.index
                };
                if (tk.application_id) {
                  temp.applications_id = tk.application_id;
                  date = tk.date
                }
                points_arr.push(temp);
              });
            } else {
              let temp = {
                id: point.id,
                index: point.index
              };
              if (point.application) {
                temp.applications_id = point.application.id;
                date = point.application.date_start
              }
              points_arr.push(temp);
            }
          });

          route.legs = route.legs.map(leg => {
            return google.maps.geometry.encoding.encodePath(leg);
          });

          Phalcon.routes.build({
            name: route.name,
            time: route.time,
            all_time: route.all_time,
            dl_time: route.dl_time,
            tk_time: route.tk_time,
            date: date,
            distance: route.distance,
            legs: JSON.stringify(route.legs)
          }, points_arr).then(answer => {
            UIkit.notification({
              message: 'Маршрут ' + route.name + ' создан успешно!',
              status: 'success',
              pos: 'bottom-right'
            });
            self.set({ routeSaving: self.get().routeSaving + 1, date: moment(date).format('YYYY-MM-DD') });
          })

        } else if (route.points) {
          let relatedPoints = [];
          let pointsIds = [];

          ////console.log(route.points);
          route.points.map(point => {
            pointsIds.push(point.id);
            if (point.dl_ids) {
              if(Array.isArray(point.dl_ids)){
                relatedPoints.push({
                  tk_id: point.id,
                  relations: point.dl_ids
                });
              } else {
                relatedPoints.push({
                  tk_id: point.id,
                  relations: point.dl_ids.split(',')
                });
              }
            } else if (point.edges) {
              relatedPoints.push({
                tk_id: point.id,
                relations: []
              });

              point.edges.forEach(function (el) {
                relatedPoints[relatedPoints.length - 1].relations.push($(el).attr('data-point-id'));
              })
            } else if (point.dl) {
              relatedPoints.push({
                tk_id: point.id,
                relations: []
              });

              point.dl.map(dl => {
                relatedPoints[relatedPoints.length - 1].relations.push(dl.id);
              })
            }
          });


          Phalcon.points.setRelatedPoints(relatedPoints).then(relations => {
            let routes_id = Phalcon.getUrlVars()['routes_id'];
            if (routes_id) {
              Phalcon.routes.remove(routes_id);
            }

            route.legs = route.legs.map(leg => {
              return google.maps.geometry.encoding.encodePath(leg);
            });

            Phalcon.routes.create({
              name: route.name,
              time: route.time,
              all_time: route.all_time,
              dl_time: route.dl_time,
              tk_time: route.tk_time,
              distance: route.distance,
              date: date,
              legs: JSON.stringify(route.legs)
            }).then(savedR => {
              let points_to_routes = [];
              route.points.map(point => {
                points_to_routes.push({
                  points_id: point.id,
                  routes_id: savedR.id,
                  index: point.index
                });
              });

              Phalcon.points.setRelatedPointsToRoutes(points_to_routes).then(answer => {
                if (answer.status == 200) {
                  UIkit.notification({
                    message: 'Маршрут ' + route.name + ' создан успешно!',
                    status: 'success',
                    pos: 'bottom-right'
                  });
                  self.set({ routeSaving: self.get().routeSaving + 1 });
                }
              });
            })
          })
        } else {
          UIkit.notification({
            message: 'Очередь выставлена не до конца! Пожалуйста, выставите очередность точек в маршруте!',
            status: 'danger',
            pos: 'bottom-right'
          });
        }
      },
      refreshPoints(p1) {
        const { editStore } = this.get();
        let { points } = editStore.get();

        points = points.map(p2 => {
          if (p1.id == p2.id) {
            p2 = p1;
          }
          return p2;
        }).filter(p => p);
        ////console.log('refreshPoints', points);

        editStore.set({ points });
      },
      setRelatedPoints() {
        let { editStore, newPoint, index, pointTksMap } = this.get();
        let { points } = editStore.get();
        if (pointTksMap) pointTksMap.clear();

        let relatedPoints = [];
        let pointsIds = [];

        points.map(point => {
          pointsIds.push(point.id);
          if (point.dl) {
            point.dl.map(dl => {
              let tk = pointTksMap.get(dl.id);
              if (!tk) {
                pointTksMap.set(dl.id, [point]);
              } else {
                tk.push(point);
                pointTksMap.set(dl.id, tk);
              }
            })
          } else if (point.dl_ids && point.dl_ids.length > 0) {
            point.dl_ids.map(dl_id => {
              let tk = pointTksMap.get(dl_id);
              if (!tk) {
                pointTksMap.set(dl_id, [point]);
              } else {
                tk.push(point);
                pointTksMap.set(dl_id, tk);
              }
            })

            // relatedPoints.push({
            //   tk_id: point.id,
            //   relations: []
            // });

            // point.edges.forEach(function (el) {
            //   relatedPoints[relatedPoints.length - 1].relations.push($(el).attr('data-point-id'));
            // })
          }
        });
      },
      dec() {
        let { step, editStore } = this.get();

        if (step <= 1) {
          Swal.fire({
            title: 'Внимание!',
            text: `Выйти из редактора маршрута ?`,
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Подтвердить',
            cancelButtonText: 'Отменить',
          }).then(result => {
            if (result.value) {
              this.options.root.navigate('/map');
              editStore.set({ points: [] });
            }
          });
        } else {
          Swal.fire({
            title: 'Внимание!',
            text: `Вы возвращаетесь к шагу ${step - 1}, некоторые данные могут быть утеряны!`,
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Подтвердить',
            cancelButtonText: 'Отменить',
          }).then(result => {
            if (result.value) {
              step--;
              this.set({ step });
            }
          });
        }
      },
      configRoute() {
        let { routeTypes } = this.get();
        const { editStore } = this.get();
        routeTypes = true;
        this.set({ routeTypes });
        if (routeTypes) {
          fetch(`http://fondo-phalcon.lc/routes/get/1?with=points`)
            .then(responce => {
              if (responce.ok) {
                return responce.json();
              } else {
                //throw new Error()
              }
            })
            .then(answer => {
              let pointsS = answer.points;
              routeTypes = true;
              editStore.set({ points: pointsS }); // just 4 test!
            }
            )
            .catch(error => {
              ////console.log(error);
            })
        }
      },
      async autoRoute() {
        let vehicles = []

        let result = await Swal.fire({
          title: 'На сколько машин составить маршрут?',
          icon: 'question',
          input: 'range',
          inputAttributes: {
            min: 1,
            max: 10,
            step: 1
          },
          inputValue: 1
        })
        
        for(var i = 0; i < result.value; i++){
          vehicles.push({
            id: `vehicleREX${i}`
          })
        }

        const { editStore, pointTksMap } = this.get();
        const { points } = editStore.get();
        // console.log({points})
        // const optimized = await ky.post('https://highwayservices.smartmonkey.io/api/v1/service?private_key=priv_L3Yl2qvZ2XPKFl09MUOnt2myoydsInr.hxxCmNDnnRtCadDj.nTArCt8Cir-Kj5t&plan_id=5ede500e4dd4d0001979fbdf', {
        //   timeout: 600000,
        //   json: {
        //     vehicles: vehicles,
        //     services: points.map(p => {
              

        //       if(p.type == 'dl') {
        //         let service

        //         service = {
        //           id: p.id.toString(),
        //           duration: 600,
        //           location: {
        //             lat: parseFloat(p.lat),
        //             lng: parseFloat(p.lon)
        //           }
        //         }

        //         let tk_arr = pointTksMap.get(p.id);
                
        //         if(tk_arr) {
        //           service.pickups = tk_arr.map(tk => {
        //             return {
        //               duration: 1200,
        //               location: {
        //                 lat: parseFloat(tk.lat),
        //                 lng: parseFloat(tk.lon)
        //               }
        //             }
        //           })
        //         }

        //         return service
        //       }
              
        //       return null
        //     }).filter(p => p)
        //   }
        // }).json()

        const optimized = await ky.post('https://highwayservices.smartmonkey.io/api/v1/services?private_key=priv_L3Yl2qvZ2XPKFl09MUOnt2myoydsInr.hxxCmNDnnRtCadDj.nTArCt8Cir-Kj5t&plan_id=5ede500e4dd4d0001979fbdf', {
          timeout: 600000,
          json: points.map(p => {
              

              if(p.type == 'dl') {
                let service

                service = {
                  id: p.id.toString(),
                  duration: 600,
                  location: {
                    lat: parseFloat(p.lat),
                    lng: parseFloat(p.lon)
                  }
                }

                let tk_arr = pointTksMap.get(p.id);
                
                if(tk_arr) {
                  service.pickup = tk_arr.map(tk => {
                    return {
                      duration: 1200,
                      location: {
                        lat: parseFloat(tk.lat),
                        lng: parseFloat(tk.lon)
                      }
                    }
                  })[0]
                }

                return service
              }
              
              return null
            }).filter(p => p)
        }).json()

        // console.log({optimized})
        
        if(optimized && optimized.solution && optimized.solution.routes) {
          let routes = optimized.solution.routes

          if(routes.length > 0 && routes[0].steps.length > 0) {

            for(var i = 0; i < routes.length; i++){
              let route = routes[i]
              
              this.clearOrder()

              for(var j = 0; j < route.steps.length; j++){
                let step = routes[i].steps[j]

                if(step.id){
                  let point = points.find(p => p.id == step.id)
                  // console.log({step, point})
                  if(point){
                    this.setOrder(point, false)
                  }
                }
              }

              let { pointOrder } = this.get()
              let path = Array.from(pointOrder)

              let index = 1
              for (const p1 of pointOrder) {
                points.map(p2 => {
                  if (p1 == p2) p2.index = index++;
                })
              }

              await window.map.helper.setRoute(path[0], path[path.length - 1], this.routeCalc, this, path);
              await this.setRoute()
            }
          }
        }
        //window.map.helper.allPathLength(points, 0.99999);
      },
      async setRoute() {
        return new Promise(async resolveRoute => {
          function sleep(ms) {
            return new Promise(resolve => setTimeout(resolve, ms));
          }

          const self = this;

          var { pointOrder, editStore, routes, distance, duration, tkTime, dlTime, routeName, legs, pointTksMap } = this.get();
          var { points } = editStore.get();
          
          if(pointOrder.size == 0) {
            UIkit.notification({
              message: 'Необходимо добавить в маршрут хотя бы 1 точку',
              status: 'danger',
              pos: 'bottom-right'
            });
            return;
          }

          var rPoints = points.filter(p => pointOrder.has(p));

          // console.log({points, rPoints})

          let tkCount = Array.from(pointOrder).filter(p => p.type == 'tk').length;
          let dlCount = Array.from(pointOrder).filter(p => p.type == 'dl').length;

          var rPointsWithoutTk = points.filter(p => {
            if (pointOrder.has(p) && p.type == "tk") return false;
            return pointOrder.has(p);
          });

          // if (!routeName || routeName == '') {
          //   UIkit.notification({
          //     message: 'Введите название маршрута!',
          //     status: 'danger',
          //     pos: 'bottom-right'
          //   });
          //   return;
          // }

          var route = {
            id: routes.length > 0 ? routes[routes.length - 1].id + 1 : 1,
            distance: distance,
            time: duration,
            all_time: duration + (tkTime * tkCount * 60) + (dlTime * dlCount * 60),
            tk_time: tkTime * 60,
            dl_time: dlTime * 60,
            name: routeName == '' ? ('Маршрут ' + (routes.length > 0 ? routes[routes.length - 1].id + 1 : 1)) : routeName,
            legs: legs,
            points: JSON.parse(JSON.stringify(rPoints))
          }

          routes.push(route);

          points = points.filter(p => {
            if (pointOrder.has(p) && p.type == "tk") return true;
            return !pointOrder.has(p);
          });

          editStore.set({ points });

          await sleep(300)

          let lastTk = [];
          points = points.filter(p => {
            if (pointOrder.has(p) && p.type == "tk") {
              ////console.log('lelgelellelele', $('#' + p.id + '-related-dls').find('li').length);
              if (!$('#' + p.id + '-related-dls').find('li').length) {
                lastTk.push(p.id);
                return false;
              }
              return true;
            }
            return !pointOrder.has(p);
          });

          editStore.set({ points });

          //console.log('asfasfasf', { points });
          if (points.length == 0) {
            // Swal.fire({
            //   title: 'Идет сохранение маршрутов!',
            //   html: 'Подождите...',
            //   onBeforeOpen: () => {
            //     Swal.showLoading()
            //     routes.map(r => {
            //       self.saveRoute(r);
            //     });
            //   }
            // })
          }

          if (lastTk.length > 0) rPoints = rPoints.filter(p => {
            if (p.type == 'tk' && !lastTk.includes(p.id)) return false;
            return true;
          });

          this.set({ routes, routeName: '' });

          this.clearOrder();
          rPointsWithoutTk.map(p => {
            let tks = pointTksMap.get(p.id);
            if (tks) tks.map(tk => {
              window.map.helper.hidePolyline(tk.id + '-' + p.id);
            })
          })

          if (lastTk.length > 0) window.map.helper.hideAll(rPoints);
          else window.map.helper.hideAll(rPointsWithoutTk);

          resolveRoute()
        })
        ////console.log({ pointOrder, routes });
      },
      saveRoutes() {
        const self = this
        const { routes } = this.get()

        if(routes.length > 0){
          Swal.fire({
            title: 'Идет сохранение маршрутов!',
            html: 'Подождите...',
            onBeforeOpen: () => {
              Swal.showLoading()
              routes.map(r => {
                self.saveRoute(r);
              });
            }
          })
        } else {
          UIkit.notification({
            message: 'Нет созданных маршрутов!',
            status: 'danger',
            pos: 'bottom-right'
          });
        }
      },
      setOrder(point, refresh = true) {
        ////console.log('setORDER', point);
        const { pointOrder, step, pointTksMap } = this.get();
        if (step === 4) {
          let tk_arr = pointTksMap.get(point.id);
          if (tk_arr) {
            tk_arr.map(tk => {
              if (!pointOrder.has(point)) {
                pointOrder.add(tk);
              }
            })

            if (!pointOrder.has(point)) {
              pointOrder.add(point);
            }

            if(refresh) this.refreshOrder();

          } else if (point.type == 'tk') {
            if (!pointOrder.has(point)) {
              pointOrder.add(point);
              if(refresh) this.refreshOrder();
            }
          }
        }
      },
      removeFromOrder(point){
        // console.log({point});
        const self = this;
        const { pointOrder, step, pointTksMap, editStore } = this.get();
        let { points } = editStore.get();
        if (step === 4) {
          if (pointOrder.has(point)) {
            // points = points.map(p => {
            //   if (p == point) p.index = '';
            //   return p;
            // })
            // editStore.set({ points });
            point.index = '';
            this.set({ distance:0, duration:0, legs:[] });
            window.map.helper.hideAllRoutes(false);
            pointOrder.delete(point);

            if(point.type == 'tk'){
              if(point.dl) point.dl.map(p => {
                p.index = '';
                pointOrder.delete(p);
              });
            } 

            window.map.helper.refreshLabels(points);
            this.refreshOrder();
            
          }
        }
      },
      clearOrder() {
        const { editStore, pointOrder } = this.get();
        var { points } = editStore.get();

        if (firstClear) {
          

          let clearedOrder = Phalcon.getUrlVars()['clear_order'] ? parseInt(Phalcon.getUrlVars()['clear_order']) : 1;
          let routes_id = Phalcon.getUrlVars()['routes_id'];
          let applications_ids = Phalcon.getUrlVars()['applications_ids'];

          if (clearedOrder) {
            points = points.map(p => {
              p.index = '';
              return p;
            });
            editStore.set({ points });
            window.map.helper.refreshLabels(points);
            window.map.helper.hideAllRoutes();
          } else {
            if (routes_id) {
              this.set({ loading: true });

              Phalcon.routes.get(routes_id).then(answer => {
                answer.legs = JSON.parse(answer.legs);

                answer.legs = answer.legs.map(leg => {
                  if(!Array.isArray(leg)){
                    leg = google.maps.geometry.encoding.decodePath(leg);
                  }

                  return leg;
                })

                this.setRelatedPoints();

                points = editStore.get().points;

                let prev = null;
                points.map((point, i) => {
                  pointOrder.add(point);
                  if (prev) {
                    window.map.helper.showLeg(prev.id + "-" + point.id, answer.legs[i - 1]);
                  }

                  prev = point;
                });

                if(answer.time == answer.all_time){
                  this.set({ tkTime: 0, dlTime: 0 });
                }

                this.set({ distance: answer.distance, duration: answer.time, legs: answer.legs, routeName: answer.name });

                window.map.helper.fitBounds(points);
              }).finally(() => {
                // console.log('final');
                this.set({ loading: false })
              });
            } else if (applications_ids) {
              Phalcon.points.all({
                group_by: 'legals',
                applications_ids: applications_ids
              }).then(answer => {
                ////console.log({ answer });
                if (answer['points'].length > 0) {
                  let points = [];
                  let points_arr = [];

                  answer['points'].map(point => {
                    if (!points[point.id]) {
                      points[point.id] = point;
                      if(point.type == 'tk') {
                        let temp = {
                          id: point.id
                        };
                        
                        if(point.application) {
                          temp.application_id = point.application.id;
                          temp.date = point.application.date_start;
                        }

                        points[point.id].tks = [temp];
                      }
                    } else {
                      if (point.items) points[point.id].items = points[point.id].items.concat(point.items);
                      if (point.dl_ids) points[point.id].dl_ids = points[point.id].dl_ids.concat(point.dl_ids);
                      if (point.polylines) points[point.id].polylines = points[point.id].polylines.concat(point.polylines);
                      if (point.polylines_names) points[point.id].polylines_names = points[point.id].polylines_names.concat(point.polylines_names);
                      if(point.type == 'tk') {
                        let temp = {
                          id: point.id
                        };
                        
                        if(point.application) {
                          temp.application_id = point.application.id;
                          temp.date = point.application.date_start;
                        }

                        points[point.id].tks.push(temp);
                      }
                    }
                  });

                  for (let key in points) {
                    points_arr.push(points[key]);
                  }


                  editStore.set({ points: [] });
                  editStore.set({ points: points_arr });
                  this.refreshStepVisible();
                }
              });
            }
          }
          firstClear = false;
        } else {
          ////console.log('fsafas2');
          points = points.map(p => {
            p.index = '';
            return p;
          });

          editStore.set({ points });
          window.map.helper.refreshLabels(points);
          window.map.helper.hideAllRoutes();
          pointOrder.clear();

          this.set({ distance: 0, duration: 0, legs: [] });
        }


      },
      routeCalc({ route, leg }, self) {
        // console.log({route, leg, self});
        let { distance, duration, legs } = self.get();
        legs.push(leg);
        distance += route.distance.value;
        duration += route.duration.value;
        self.set({ distance, duration, legs });
      },
      async refreshOrder() {
        // console.log("refreshOrder")
        function sleep(ms) {
          return new Promise(resolve => setTimeout(resolve, ms));
        }

        const { pointOrder, step, editStore } = this.get();
        var { points } = editStore.get();

        if (step === 4) {
          var i = 1, prev = null, path = [];
          this.set({ distance:0, duration:0, legs:[] });

          for (const p1 of pointOrder) {
            path.push(p1);

            points = points.map(p2 => {
              if (p1 == p2) p2.index = i++;
              return p2;
            })

            prev = p1;
          }
          
          window.map.helper.setRoute(path[0], path[path.length - 1], this.routeCalc, this, path);

          editStore.set({ points });
          window.map.helper.refreshLabels(points);
        }
      }
    },
    components: {
      Point,
      PointList,
      RoutCard,
      WayPoint,
      FakeWaypoint,
      PointListWithRelation,
      Helper
    },
    data() {
      return {
        groupBy: 'points',
        orders: [],
        legals: [],
        routeTypes: false,
        headerInfoVisible: false,
        newPoint: null,
        distance: 0,
        duration: 0,
        routeName: '',
        travel: 0,
        step: 1,
        index: 1,
        tkTime: 40,
        dlTime: 20,
        legs: [],
        pointListToDownload: '',
        pointOrder: new Set(),
        pointTksMap: new Map(),
        points: null,
        pointsS: null,
        pointListActions: ['remove'],
        routes: [],
        routeSaving: 0,
        isTypeSelect: false,
        isDraggable: true,
        loading: false,
        vehicles: [{
          id: 'vehicleREX1'
        }],
        editStore: new Store({
          fakePoints: new Map(),
          pointComponents: new Map()
        }),
        textData: [
          "<i>Перетаскивая маркеры</i> на карте, вы можете изменить адрес точки",
          "<i>Клик мыши по маркеру</i> на карте, поменяет тип точки: <b>левая кнопка</b> - доставка, <b>правая кнопка</b> - забор",
          "<b><i>Левый клик мыши по <b style='color:#0cca0c'>забору</b></i></b> - Делает точку забору <b style='color:#bcbce5'>активной</b><br><b><i>Левый клик мыши по <b style='color:red'>доставке</b></i></b> - Добавляет точку доставки к <b style='color:#bcbce5'>активному</b> забору",
          "<b><i>Левый клик мыши по маркеру</i></b> - добавить точку в маршрут.<br><b><i>Правый клик мыши по маркеру</i></b> - убрать точку из маршрута.",
        ]
      }
    },
    computed: {
      distanceText({ distance }) {
        return (distance / 1000).toFixed(1).toString() + ' км'
      },
      durationText({ duration }) {
        let hours = Math.floor(duration / 60 / 60);
        if (hours) duration -= (hours * 60 * 60);

        let minutes = Math.ceil(duration / 60);
        return (hours ? hours + ' ч ' : '') + minutes + ' мин'
      },
      durationTextWithPoints({ pointOrder, duration, tkTime, dlTime }) {
        if (pointOrder) {
          let tkCount = Array.from(pointOrder).filter(p => p.type == 'tk').length;
          let dlCount = Array.from(pointOrder).filter(p => p.type == 'dl').length;

          duration += (tkTime * tkCount * 60) + (dlTime * dlCount * 60);
          let hours = Math.floor(duration / 60 / 60);
          if (hours) duration -= (hours * 60 * 60);

          let minutes = Math.ceil(duration / 60);
          return (hours ? hours + ' ч ' : '') + minutes + ' мин'
        }
        return '0 мин';
      }
    }
  }
</script>