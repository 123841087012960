
<Sidenav {session} />
<Navbar {session} />

<div id="work-zone" class="padding">
  <svelte:component {session} this={Navigation} />
</div>

<div id="modal"></div>

<script>
  import ENV from 'config/env.js';

  import Router from 'modules/router.js';
  import Phalcon from 'modules/phalcon.js';

  import Dashboard from 'pages/Dashboard.html';
  import Applics from 'pages/Applics.html';
  import Users from 'pages/Users.html';
  import MapComponent from 'pages/Map.html';
  import RoutesComponent from 'pages/RoutesEdit.html';
  import Auth from 'pages/Auth.html';
  import Profile from 'pages/Profile.html';
  import SettingsGroups from 'pages/SettingsGroups.html';
  import Confirm from 'pages/ConfirmRegistration.html';
  import PasswordChange from 'pages/PasswordChange.html';

  import Sidenav from 'UI/Sidenav.html';
  import Navbar from 'UI/Navbar.html';
  import AuthService from 'global/services/AuthService.js';

  export default {
    components: { Sidenav, Navbar },

    data() {
      return {
        fixedSideNav: false,
        table: {},
        pathname: window.location.pathname,
        session: null,
        Phalcon
      }
    },

    async oncreate() {
      const self = this;
      var { table } = this.get();
      var pathname = window.location.pathname.replace('/', '');
      const session = await Phalcon.session.get();
      if (session.status == 200 && session.group_key != "dlboys" || session.status == 200 && session.is_admin){
          window.session = session;
          AuthService.setToken(window.session.api_v1_token)
          AuthService.setSession(session)

          // setInterval(() => {
          //   Phalcon.go.token.refresh().then(answer => {
          //     if(answer && answer.token){
          //       AuthService.setToken(answer.token)
          //     } else {
          //       Phalcon.session.end().then(answer => { 
          //           window.location.replace('/auth'); 
          //       });
          //     }
          //   })
          // }, 1000 * 60 * 60 * 50);

          if (session.id) this.set({ session });
          if (session.to_paid) { //switch on! to test;
            Swal.fire({
              title: 'Недостаточно средств!',
              text: "Необходимо пополнить баланс!",
              type: 'warning',
              showCancelButton: true,
              confirmButtonColor: '#e30613',
              cancelButtonColor: '#F2C94C',
              cancelButtonText: 'Позже',
              allowEscapeKey: false,
              allowOutsideClick: false,
              confirmButtonText: 'Пополнить счет'
            }).then((result) => {
              if (result.value) {
                Router.goTo('my-balance');
              }
            })
          }
        } else {
          let auth_urls = ['auth','register','register/final', 'password/reset/page', 'password/change', 'password/change/request', 'map/fast/route'];
          if(!auth_urls.includes(pathname)){
            window.location.replace('/auth');
          }
        }

      window.onpopstate = function (e) {
        self.set({ pathname: window.location.pathname });
      };

      window.app = this;
    },

    methods: {
      openSidenav() {
        const { sidenav } = this.get();
        sidenav.open();
      },

      navigate(pathname) {
        if (pathname) {
          history.pushState({ page: 1 }, "title 1", pathname);
          window.dispatchEvent(new Event('popstate'));
        }
      }
    },

    computed: {
      Navigation: ({ pathname }) => {
        ////console.log('pathname', pathname);
        switch (pathname) {
          case '/accounts': {
            ym(ENV.YM_ID, 'hit', '/accounts',{
              title: 'Аккаунты',
            });
            return Applics};
          case '/map': {
            ym(ENV.YM_ID, 'hit', '/map',{
              title: 'Карта',
            });
            return MapComponent};
          case '/map/routes': {
            ym(ENV.YM_ID, 'hit', '/',{
              title: 'Создание маршрута',
            });
            return RoutesComponent};
          case '/users': {
            ym(ENV.YM_ID, 'hit', '/users',{
              title: 'Сотрудники',
            });
            return Users};
          case '/profile': {
            ym(ENV.YM_ID, 'hit', '/profile',{
              title: 'Профиль',
            });
            return Profile};
          case '/settings/groups': {
            ym(ENV.YM_ID, 'hit', '/settings/groups',{
              title: 'Настройки групп',
            });
            return SettingsGroups};
          case '/session/end': Phalcon.session.end().then(answer => { window.location.replace('/'); }); break;
          case '/password/reset/page':
            setTimeout(() => {
              ym(ENV.YM_ID, 'hit', '/password/reset/page',{
                title: 'Сброс пароля',
              });
              var authComponent = new PasswordChange({
                target: document.getElementById('modal')
              });
            }, 100);
            return Dashboard;
          case '/confirm':
            setTimeout(() => {
              ym(ENV.YM_ID, 'hit', '/confirm',{
                title: 'Подтверждение',
              });
              var authComponent = new Confirm({
                target: document.getElementById('modal')
              });
            }, 100);
            return Dashboard;
        }
      }
    }
  };
</script>

<style>.n__btn--primary {
  background-color: #c70017;
  color: white;
  font-weight: 500;
  border-radius: 0;
  line-height: 36px;
  text-transform: unset;
  padding: 0 15px;
  font-size: 16px;
  height: 100%; }
  .n__btn--primary img {
    margin-top: -3px;
    margin-right: 7px; }

h1 {
  color: purple; }

main {
  padding: 15px; }

.breadcrumbs {
  padding-left: 70px; }

.sidenav-open-trigger {
  position: absolute;
  font-size: 20px;
  padding-left: 20px; }

.fixed-menu-padding {
  padding-left: 315px; }

.fixed-menu {
  transform: translateX(0); }

.scroll-content {
  height: calc(100vh - 64px) !important;
  overflow-y: auto;
  overflow-x: hidden;
  padding-bottom: 50px; }

#work-zone {
  background-color: #f5f5f5;
  position: relative;
  overflow-y: auto;
  padding-left: 60px;
  height: calc(100vh - 60px); }

#work-zone.padding {
  padding: 15px 15px 15px 75px; }
</style>
