<div ref:wrapper class="uk-width uk-grid uk-grid-collapse">
    <div class="uk-width-auto" style="width: 500px">
        {#if points}
            <RoutEdit {points} {orders} {legals} {dlboys} {groupBy} {pointsToReturn} bind:step bind:routes bind:pointsToReturn />
        {/if}
    </div>
    {#if step === 4}
        <div class="uk-width-auto uk-position-relative" style="z-index: 9;">
            <div class:routeListVisible style="width: 200px; padding: 0 15px; display: none;">
                <RoutList routeEditor="{true}" on:remove="removeRoute(event)" { routes } />
            </div>
            <div class="hide-btn uk-flex" on:click="set({ routeListVisible: !routeListVisible })">
                <img class="uk-margin-auto" src="/svg/icons/white-arrow.svg" class:routeListVisible />
            </div>
        </div>
    {/if}
    <div class="uk-width-expand">
        <div ref:map id="google-map"></div>
        <MapBaloonTools />
    </div>
</div>

<script>
    import MapHelper from 'modules/mapHelper.js';
    import pathOptimizer from 'modules/pathOptimizer.js';

    import MapList from 'components/map/MapDetail.html';
    import MapBaloonTools from 'components/map/MapBaloonTools.html'
    import RoutEdit from 'components/routes/RoutesEdit.html';
    import RoutList from 'components/routes/RoutesList.html';

    import { Store } from 'svelte/store.js';

    window.map = {
        store: new Store({
            googleMap: null,
            markers: new Map(),
            polylines: new Map(),
        }),
        helper: MapHelper
    };

    export default {
        components: {
            MapList,
            RoutEdit,
            RoutList,
            MapBaloonTools
        },
        data() {
            return {
                step: 1,
                routes: [],
                routeListVisible: false,
                pointsToReturn: [],
                points: [],
                orders: [],
                legals: [],
                groupBy: 'points'
            }
        },
        ondestroy() {
            $('#work-zone').addClass('padding');
        },
        onupdate({changed, current}){
            if(changed.routes && current.routes){
                if(current.routes.length > 0){
                    this.setRouteListVisible(true);
                } else {
                    this.setRouteListVisible(false);
                }
            }
        },
        oncreate() {
            $('#work-zone').removeClass('padding');
            const { Phalcon } = this.options.root.get();
            window.nav.set({
                breadcrumb: [{
                    name: 'Карта',
                    link: '/map'
                }, {
                    name: 'Редактор маршрута',
                    link: '/map/routes'
                }],
                title: 'Шаг 1 из 4. Добавление точек'
            });

            window.map.store.set({
                googleMap: new google.maps.Map(this.refs.map, {
                    center: { lat: 55.752107, lng: 37.622345 },
                    zoom: 10,
                    maxZoom: 18,
                    disableDefaultUI: true
                })
            });

            window.map.helper.init(window.map.store.get(), {
                clusterize: false
            });
            window.map.helper.pathOptimizer = pathOptimizer;

            let params = {
                with: 'dl,applications'
            };
            let routes_id = Phalcon.getUrlVars()['routes_id'];
            let group_by = Phalcon.getUrlVars()['groupBy'];
            let date_start = Phalcon.getUrlVars()['date_start'];
            let date_end = Phalcon.getUrlVars()['date_end'];
            let applications_ids = Phalcon.getUrlVars()['applications_ids'];
            
            if (routes_id) params.routes_id = routes_id;
            if (group_by) params.group_by = group_by;
            if (date_start) params.date_start = date_start;
            if (date_end) params.date_end = date_end;
            
            if(!applications_ids){
                Phalcon.points.all(params).then(answer => {
                    answer['points'] = answer['points'].sort((a, b) => {
                        if (a.index > b.index) return 1;
                        if (a.index < b.index) return -1;
                        return 0;
                    });

                    let index = 1;
                    answer['points'] = answer['points'].map(p => {
                        p.index = index++;
                        return p;
                    });

                    this.set({
                        points: answer['points']
                    });
                })
            }
        },
        methods: {
            removeRoute(route){
                let { routes, pointsToReturn } = this.get();
                routes = routes.filter(r => r.id != route.id);
                pointsToReturn = route.points;

                this.set({ routes, pointsToReturn });
            },
            setRouteListVisible(routeListVisible){
                this.set({routeListVisible});
            }
        }
    }
</script>

<style>
    ref:wrapper {
        height: 100%;
    }
  
    ref:map {
        width: 100%;
        height: 100%;
    }

    div.routeListVisible {
        display: block !important;
    }

    img.routeListVisible {
        transform: rotateZ(0);
    }

    img {
        transform: rotateZ(180deg);
        transition: transform .3s ease-out;
    }
    
    .hide-btn {
        cursor: pointer;

        position: absolute;
        width: 25px;
        height: 35px;
        right: -25px;
        top: 25px;

        background: #4F4F4F;
        border-radius: 0px 5px 5px 0px;
    }
</style>